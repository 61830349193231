import axios from 'axios';
import md5 from 'js-md5';

export default {
    data () {
        return {
            loading: false,
            items: [],
            items_handled: [],
            item: '',
            item_index: 0,
            item_composed: false,
            step: '',
            quantity: 1,
            show_modal: false,
            variant: null,
            variants: [],
            addons: [],
            kitchen_groceries: [],
            cardnumber: null,
            errors: {
                openprice: false,
                variants: false,
                addons: [],
                kitchen_groceries: [],
            },
        };
    },
    methods: {

        variantSelect (variant) {
            this.variant = variant;

            this.confirmVariant();
        },

        nextStep (from) {
            // let steps = {
            //     variants: 'addons',
            //     variants: 'addons',
            // }
            if (from == 'details') {
                if (this.item.attributes.pos_openprice == true) {
                    this.step = 'openprice';
                } else {
                    this.nextStep('openprice');
                }
            } else if (from == 'openprice') {
                if (this.item.attributes.variants.length > 0) {
                    this.step = 'variants';
                } else {
                    this.nextStep('variants');
                }
            } else if (from == 'variants') {
                if (this.item.attributes.addons.filter(addon => {
                    return addon.pos_active == true;
                }).length > 0) {
                    this.step = 'addons';
                } else {
                    this.nextStep('addons');
                }
            } else if (from == 'addons') {
                if (this.item.attributes.kitchen_groceries.length > 0) {
                    this.step = 'kitchen_groceries';
                } else {
                    this.nextStep('kitchen_groceries');
                }
            // } else if (from == 'kitchen_groceries') {
                // this.step = 'card';
                // if (this.item.attributes.card != false) {
                //     this.cardnumber = null;
                //     // this.cardnumber = '7000001120561498492'
                //     this.step = 'card';
                //     console.log(this.$refs)
                //     this.$refs.walletaddcredit.open();
                // } else {
                //     this.nextStep('card');
                // }
            } else if (from == 'kitchen_groceries' || from == false) {
            // } else if (from == 'card' || from == false) {

                this.item_index++;

                // set data to item
                this.item.quantity = this.quantity;
                this.item.kitchen_groceries = this.kitchen_groceries;
                this.item.addons = this.addons;
                this.item.variant = this.variant;

                // add item to handled items
                this.items_handled.push(this.item);

                // go to next item
                this.nextItem();
            }
        },

        confirmOpenPrice () {

            // if (this.item.attributes.pos_price < 0) {
            //     this.errors.openprice = true
            //     return
            // }

            this.nextStep('variants');
        },

        confirmVariant () {

            this.errors.variants = false;

            if (!this.variant) {
                this.errors.variants = true;
                return;
            }

            this.nextStep('variants');
        },

        confirmAddons () {
            // todo own function
            // errors in addons

            let addons = [];
            // let self = this
            let quantity_error = false;

            this.errors.addons = [];

            this.addons.forEach(function (addon_group) {
                // let required = addon_group.required
                let quantity = 0;

                addon_group.options.forEach(function (addon) {
                    addon.error = false;

                    if (addon.quantity > 0) {
                        quantity += parseFloat(addon.quantity);
                        addons.push(addon);
                    }

                    if ((addon.quantity_min && addon.quantity < addon.quantity_min) || (addon.quantity_max && addon.quantity > addon.quantity_max)) {
                        addon.error = quantity_error = true;
                    }
                });

                if ((addon_group.quantity_min && quantity < addon_group.quantity_min) || (addon_group.quantity_max && quantity > addon_group.quantity_max)) {
                    addon_group.error = quantity_error = true;
                }
                // if (required && quantity == 0) {
                //     self.errors.addons.push(addon_group.description)
                // }
            });

            if (this.errors.addons.length > 0 || quantity_error === true) {
                return;
            }

            this.addons = addons;

            this.nextStep('addons');
        },

        confirmKitchenGroceries () {
            // let kitchen_groceries = []
            let self = this;

            // reset errors
            this.errors.kitchen_groceries = [];

            this.item.attributes.kitchen_groceries.forEach(function (line) {
                let found = false;
                self.kitchen_groceries.forEach(function (selected) {
                    if (selected.description == line.description) {
                        found = true;
                    }
                });

                if (!found) {
                    self.errors.kitchen_groceries.push(line.description);
                }
            });

            if (this.errors.kitchen_groceries.length > 0) {
                return;
            }

            this.nextStep('kitchen_groceries');
        },

        // Kitchen Groceries functions
        setKitchenGrocery (description, value) {
            // eslint-disable-next-line no-unused-vars
            let found = false;
            this.kitchen_groceries.forEach(function (line) {
                if (line.description == description) {
                    found = true;
                    line.value = value;
                }
            });

            if (!found) {
                this.errors.kitchen_groceries.splice(this.errors.kitchen_groceries.indexOf(description, 0), 1);
                this.kitchen_groceries.push({ 'description': description, 'value': value });
            }
        },

        isKitchenGrocerySelected (description, value) {
            let selected = false;
            this.kitchen_groceries.forEach(function (line) {
                if (line.description == description && line.value == value) {
                    selected = true;
                }
            });
            return selected;
        },

        // openprice
        setOpenprice (value) {
            let negative = false;
            if (this.item.attributes.pos_price.toString().includes('-') && !value.toString().includes('-')) {
                negative = true;
            }
            this.item.attributes.pos_price = parseFloat(value / 100);

            if (negative) {
                this.item.attributes.pos_price = '-' + this.item.attributes.pos_price;
            }
        },

        // check if field has error
        hasError (field, value) {
            let has_error = false;
            this.errors[field].forEach(function (error) {
                if (error == value) {
                    has_error = true;
                }
            });
            return has_error;
        },

        addonRadioSelect (addon, option) {
            addon.options.forEach(addon_option => {
                if (option.id == addon_option.id) {
                    addon_option.quantity = this.quantity;
                } else {
                    addon_option.quantity = 0;
                }
            });
        },

        changeAddonQuantity (addon, quantity, max = false) {
            if ((addon.quantity) + quantity < 0) {
                return;
            }

            addon.quantity = parseFloat((addon.quantity)) + quantity;
            addon.quantity_init = addon.quantity;
        },

        // addItem(quantity = 1) {
        addItem (showDetails = false) {

            this.item_index = 0;
            this.item_composed = false;
            this.items = [];
            this.items_handled = [];

            // set the quantity
            // let quantity = this.initialquantity ? parseFloat(this.initialquantity.replace('x', '')) : 1
            this.quantity = this.initialquantity ? parseFloat(this.initialquantity.replace('x', '')) : 1;

            // clone initial item
            let initialitem = JSON.parse(JSON.stringify(this.initialitem));

            // initialitem.quantity = this.initialquantity

            // check for composed items
            if (initialitem.attributes.composeditems.data.length > 0) {
                this.items = initialitem.attributes.composeditems.data;
                this.item_composed = true;
            } else {
                this.items.push(initialitem);
            }
            // select het next item in the array
            this.nextItem(showDetails);

            // todo: optional. Only do this for new items, if item already in cart, scroll to this item.
            //scroll to bottom of the items-div, to have visual what item is added
            this.transactionOverviewScrollToBottom();
        },

        transactionOverviewScrollToBottom () {
            let itemOverviewContainer = document.querySelector('.transaction-scroller');

            if (itemOverviewContainer) {
                itemOverviewContainer.scrollTop = itemOverviewContainer.scrollHeight;
            }
        },

        addItemByScannedBarcode (item) {
            this.item_index = 0;

            this.items = [];
            this.items_handled = [];
            this.quantity = 1;
            this.items.push(item);
            this.nextItem();
        },

        nextItem (showDetails = false) {

            // check if there is a next item
            // when there is a next item handle the item
            if (this.items[this.item_index]) {
                this.handleItem(this.items[this.item_index], showDetails);

                return;
            }
            // when no item is next, set the items to the cart
            else {
                let self = this;
                let line_id = null;

                if (this.item_composed) {
                    let initialitem = JSON.parse(JSON.stringify(this.initialitem));
                    initialitem.quantity = this.quantity;
                    initialitem.variant = null;
                    initialitem.addons = [];

                    line_id = this.addItemToCart(initialitem);
                }

                // loop through the handled items
                this.items_handled.forEach(function (item) {

                    // set parent_line_id
                    if (line_id) {
                        item.line_parent_id = line_id;
                    }

                    // set composed_child
                    item.composed_child = self.item_composed;

                    self.addItemToCart(item);
                });

                // close the modal
                this.close();
            }
        },

        handleItem (item, showDetails = false) {
            // set item
            this.item = item;

            // reset the values and errors
            this.variant = null;
            this.kitchen_groceries = [];
            this.addons = JSON.parse(JSON.stringify(this.item.attributes.addons.filter(addon => {
                return addon.pos_active == 1;
            })));

            this.errors = {
                addons: [],
                kitchen_groceries: [],
            };

            if (this.item.attributes.pos_openprice == true) {
                // set the quantity
                if (this.initialquantity) {
                    if (!this.initialquantity.includes('x') && this.initialquantity) {
                        this.quantity = 1;
                        this.setOpenprice(this.initialquantity);
                        // this.item.attributes.pos_price = parseFloat(this.initialquantity / 100)
                        this.item.attributes.pos_openprice = false;
                    }
                } else {
                    this.quantity = 1;
                }
                // let quantity = parseFloat(this.initialquantity.replace('x', ''))
                // this.quantity = quantity > 0 ? quantity : 1

            }

            // open modal?
            if (this.item.attributes.pos_openprice == true ||
                this.item.attributes.addons.filter(addon => {
                    return addon.pos_active == true;
                }).length > 0 ||
                this.item.attributes.kitchen_groceries.length > 0 ||
                this.item.attributes.variants.length > 0 ||
                // this.item.attributes.card != false ||
                showDetails) {
                this.openModal(showDetails);
                // openmodal = true
                return;
            }

            // force to end of the wizard
            this.nextStep(false);
        },

        addQuantity () {
            this.quantity = this.quantity + 1;

            // add addons
            this.addons.forEach((addon) => {
                // addons has only options when addons not yet confirmed
                if (addon.options) {
                    addon.options.forEach((option) => {
                        if (option.quantity > 0) {
                            option.quantity = parseFloat(option.quantity) + parseFloat(option.quantity_init ? option.quantity_init : 1);
                        }
                    });
                } else {
                    if (addon.quantity > 0) {
                        addon.quantity = parseFloat(addon.quantity) + parseFloat(addon.quantity_init ? addon.quantity_init : 1);
                    }
                }
            });
        },
        subQuantity () {
            if (this.quantity > 1) {
                this.quantity = this.quantity - 1;

                // subtract addons
                this.addons.forEach((addon) => {
                    // addons has only options when addons not yet confirmed
                    if (addon.options) {
                        addon.options.forEach((option) => {
                            if (option.quantity > 0) {
                                option.quantity = parseFloat(option.quantity) - parseFloat(option.quantity_init ? option.quantity_init : this.quantity);
                            }
                        });
                    } else {
                        if (addon.quantity > 0) {
                            addon.quantity = parseFloat(addon.quantity) - parseFloat(addon.quantity_init ? addon.quantity_init : this.quantity);
                        }
                    }
                });
            }
        },

        addItemToCart (item, hourly_rate_item = false) {
            // let id = (Math.random()).toString(22).substr(2, 22)
            let self = this;

            // set the rate
            let rate = 0;
            if (!item.composed_child && !hourly_rate_item) {
                rate = item.variant ? item.variant.attributes.price : item.attributes.pos_price;
            }

            // set the data of the item
            let data = {
                parent_id: item.line_parent_id ? item.line_parent_id : null,
                item_id: item.id,
                item_parent_id: item.parent_id,
                addon: false,
                composed_child: item.composed_child ? item.composed_child : false,
                barcode: item.attributes.barcode,
                description: item.attributes.description + (item.variant ? ' - ' + item.variant.attributes.label : ''),
                // thumbnail:  item.attributes.thumbnail_url,
                taxrate: item.attributes.taxrate,
                rate: rate ? rate : 0,
                kitchen_groceries: item.kitchen_groceries,

                variant_id: item.variant ? item.variant.id : null,
                variant_label: item.variant ? item.variant.attributes.label : null,

                // addons?!
                addons: item.addons,

                card: item.attributes.card ? item.attributes.card : null,
                cardnumber: this.cardnumber,

                notes: item.notes,
            };

            data.id = md5(JSON.stringify(data));

            data.quantity = item.quantity;

            this.$store.dispatch('transaction/addItem', data);

            // add linkeditems
            if (item.attributes.linkeditems && item.attributes.linkeditems.pos) {
                item.attributes.linkeditems.pos.forEach(function (linkeditem) {
                    let data_linkeditem = {
                        parent_id: data.id,
                        item_id: linkeditem.id,
                        item_parent_id: linkeditem.attributes.item_parent_id,
                        addon: true,
                        uneditable: true,
                        barcode: linkeditem.attributes.barcode,
                        description: linkeditem.attributes.description,
                        thumbnail: null,
                        taxrate: linkeditem.attributes.taxrate,
                        rate: linkeditem.attributes.pos_price ? linkeditem.attributes.price : 0,
                        kitchen_groceries: [],
                    };
                    data_linkeditem.id = md5(JSON.stringify(data_linkeditem));
                    data_linkeditem.quantity = parseInt(data.quantity),

                    self.$store.dispatch('transaction/addItem', data_linkeditem);
                });
            }

            // add addons
            item.addons.forEach(function (addon) {
                let data_addon = {
                    parent_id: data.id,
                    item_id: addon.item_id,
                    item_parent_id: addon.item_parent_id,
                    addon: true,
                    barcode: addon.barcode,
                    description: addon.description,
                    thumbnail: null,
                    taxrate: addon.taxrate,
                    rate: addon.price ? addon.price : 0,
                    kitchen_groceries: [],
                };
                data_addon.id = md5(JSON.stringify(data_addon));
                data_addon.quantity = parseInt(addon.quantity);

                self.$store.dispatch('transaction/addItem', data_addon);

                // Add cashfunctions for addon if they exist
                if (addon.cashfunctions && addon.cashfunctions.length > 0) {
                  addon.cashfunctions.forEach(function(cashfunction) {
                      let data_cashfunction = {
                          type: 'cashinout',
                          parent_id: data_addon.id,  // Link to the addon instead of main item
                          cashfunction_id: cashfunction.id,
                          item_id: null,
                          item_parent_id: null,
                          addon: true,
                          barcode: null,
                          description: cashfunction.description,
                          thumbnail: null,
                          taxrate: 0,
                          rate: cashfunction.price,
                          rate_without_discount: cashfunction.price,
                          kitchen_groceries: [],
                      };
                      data_cashfunction.id = md5(JSON.stringify(data_cashfunction));
                      data_cashfunction.quantity = addon.quantity;
                      data_cashfunction.quantity_init = addon.quantity;


                      self.$store.dispatch('transaction/addItem', data_cashfunction);
                  });
              }
            });

            // add cashfunctions
            item.attributes.cashfunctions.forEach(function (cashfunction) {
                let data_cashfunction = {
                    type: 'cashinout',
                    parent_id: data.id,
                    cashfunction_id: cashfunction.id,
                    item_id: null,
                    item_parent_id: null,
                    addon: true,
                    barcode: null,
                    description: cashfunction.description,
                    thumbnail: null,
                    taxrate: 0,
                    rate: cashfunction.price,
                    rate_without_discount: cashfunction.price,
                    kitchen_groceries: [],
                };
                data_cashfunction.id = md5(JSON.stringify(data_cashfunction));
                data_cashfunction.quantity = item.quantity,
                data_cashfunction.quantity_init = item.quantity,

                self.$store.dispatch('transaction/addItem', data_cashfunction);
            });

            // add card
            if (item.attributes.card && item.attributes.card_type === 'cashcard') {
                this.$refs.walletaddcredit.open();
            }

            this.$emit('itemadded');

            return data.id
            // this.close()
        },

        hashCode (s) {
            return s.split("").reduce(function (a, b) {
                a = ((a << 5) - a) + b.charCodeAt(0);
                return a & a;
            }, 0);
        },

        openModal (show_details = true) {
            // this.resetValues();

            if (show_details == false) {
                if (this.item.attributes.pos_openprice == true) {
                    this.step = 'openprice';
                } else {
                    this.nextStep('openprice');
                }
            } else {
                this.step = 'details';
            }

            document.body.classList.add("modal-open");

            // open modal
            this.show_modal = true;

            this.$emit('modalopened');
        },

        close () {
            // this.resetValues();

            document.body.classList.remove("modal-open");

            // close modal
            this.show_modal = false;
        },

        // card
        cardStatus () {
            // has cardnumber
            if (!this.cardnumber) {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Geen kaartnummer",
                    text: "Geef een kaartnummer in",
                }, 2000);

                return;
            }

            // todo loading
            this.loading = true;

            axios.post('/cards/status', {
                'cardnumber': this.cardnumber,
                'type': this.item.attributes.card,
            }).then(response => {
                // this.$notify({ group: "notifications", type: 'success', title: "Succes", text: "Kaart is geldig" }, 2000);
                this.item.card = {
                    'status': response.data.status,
                    'cardnumber': this.cardnumber,
                    'type': this.item.attributes.card,
                };
                // this.nextStep('card')
            }).catch(error => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
                console.error(error);
            }).finally(() => {
                this.loading = false;
            });
        },


        cardActivate () {
            // has cardnumber
            if (!this.cardnumber) {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Geen kaartnummer",
                    text: "Geef een kaartnummer in",
                }, 2000);

                return;
            }

            // todo loading
            this.loading = true;

            axios.post('/cards/activate', {
                'cardnumber': this.cardnumber,
                'type': this.item.attributes.card,
            }).then(response => {
                // this.$notify({ group: "notifications", type: 'success', title: "Succes", text: "Kaart is geldig" }, 2000);
                this.item.card = {
                    'status': response.data.status,
                    'cardnumber': this.cardnumber,
                    'type': this.item.attributes.card,
                    'reference': response.data.reference,
                };
                // this.nextStep('card')
            }).catch(error => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
                console.error(error);
            }).finally(() => {
                this.loading = false;
            });
        },

        cardCancel () {
            // has cardnumber
            if (!this.cardnumber) {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Geen kaartnummer",
                    text: "Geef een kaartnummer in",
                }, 2000);

                return;
            }

            // todo loading
            this.loading = true;

            axios.post('/cards/cancel', {
                'reference': this.item.card.reference,
                'cardnumber': this.item.card.cardnumber,
            }).then(response => {
                console.log(response);
            }).catch(error => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
                console.error(error);
            }).finally(() => {
                this.loading = false;
            });
        },
    },

    computed: {
        transaction () {
            return this.$store.getters['transaction/transaction'];
        },
    },
};
